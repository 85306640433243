import React, { useState } from 'react';
import { Star, X } from 'lucide-react';
import { supabase } from '../supabaseClient';

interface FeedbackModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ isOpen, onClose }) => {
  const [stars, setStars] = useState<number>(0);
  const [hoveredStar, setHoveredStar] = useState<number>(0);
  const [feedbackText, setFeedbackText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      setErrorMessage(null);

      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        throw new Error('Nicht eingeloggt');
      }

      const { error } = await supabase.functions.invoke('submit-feedback', {
        body: JSON.stringify({
          stars,
          feedbackText,
          userId: user.id
        })
      });

      if (error) {
        throw error;
      }

      setSubmitSuccess(true);
      setTimeout(() => {
        onClose();
        setStars(0);
        setFeedbackText('');
        setSubmitSuccess(false);
      }, 2000);

    } catch (error) {
      console.error('Fehler beim Senden des Feedbacks:', error);
      setErrorMessage(
        error instanceof Error
          ? error.message
          : 'Fehler beim Senden des Feedbacks'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4 relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-content-light hover:text-content transition-colors"
        >
          <X size={24} />
        </button>

        <h2 className="text-xl font-bold mb-4 text-secondary-600">Ihr Feedback</h2>

        {errorMessage && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
            {errorMessage}
          </div>
        )}

        <div className="flex justify-center mb-6">
          {[1, 2, 3, 4, 5].map((starNumber) => (
            <Star
              key={starNumber}
              size={32}
              className={`cursor-pointer transition-colors ${starNumber <= (hoveredStar || stars)
                  ? 'text-primary-400 fill-primary-400'
                  : 'text-gray-300'
                }`}
              onClick={() => setStars(starNumber)}
              onMouseEnter={() => setHoveredStar(starNumber)}
              onMouseLeave={() => setHoveredStar(0)}
            />
          ))}
        </div>

        <textarea
          className="w-full p-2 border rounded mb-4 h-32 focus:border-secondary-400 focus:ring-2 focus:ring-secondary-100 outline-none"
          placeholder="Teilen Sie uns Ihre Gedanken mit..."
          value={feedbackText}
          onChange={(e) => setFeedbackText(e.target.value)}
        />

        <div className="flex justify-end">
          <button
            className={`px-4 py-2 rounded text-white ${isSubmitting
                ? 'bg-gray-400'
                : submitSuccess
                  ? 'bg-secondary-500'
                  : 'bg-primary-500 hover:bg-primary-600'
              }`}
            onClick={handleSubmit}
            disabled={isSubmitting || submitSuccess || stars === 0}
          >
            {isSubmitting
              ? 'Wird gesendet...'
              : submitSuccess
                ? 'Gesendet!'
                : 'Feedback senden'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedbackModal;
