let badWords: string[] = [];

export function updateBadWords(newWords: string[]) {
  badWords = newWords;
}

export function filterContent(text: string): string {
  let filteredText = text;
  badWords.forEach(word => {
    const regex = new RegExp(word, 'gi');
    filteredText = filteredText.replace(regex, '****');
  });
  return filteredText;
}