import React, { useEffect, useState, useRef } from 'react';

interface BackgroundProps {
  children: React.ReactNode;
  showAudioControls: boolean;
}

interface WordData {
  x: number;
  y: number;
  opacity: number;
  velocityX: number;
  velocityY: number;
}

const allWords = [
  'Geschichten', 'Fantasie', 'Abenteuer', 'Märchen', 'Erzählen', 'Vorlesen', 'Kreativität',
  'Zauber', 'Helden', 'Drachen', 'Feen', 'Magie', 'Reise', 'Traum', 'Wunder', 'Entdeckung',
  'Mut', 'Freundschaft', 'Liebe', 'Weisheit', 'Rätsel', 'Geheimnis', 'Schatz', 'Legende'
];

const Background: React.FC<BackgroundProps> = ({ children, showAudioControls }) => {
  const [wordsData, setWordsData] = useState<WordData[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateWordCount = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const area = width * height;
      const wordCount = Math.floor(area / 40000);
      return Math.min(Math.max(wordCount, 5), allWords.length);
    };

    const initializeWords = () => {
      const wordCount = updateWordCount();
      const shuffled = [...allWords].sort(() => 0.5 - Math.random());
      const selectedWords = shuffled.slice(0, wordCount);

      const newWordsData = selectedWords.map(() => ({
        x: Math.random() * 100,
        y: Math.random() * 100,
        opacity: Math.random() * 0.45 + 0.05,
        velocityX: (Math.random() - 0.5) * 0.2,
        velocityY: (Math.random() - 0.5) * 0.2,
      }));
      setWordsData(newWordsData);
    };

    initializeWords();
    window.addEventListener('resize', initializeWords);

    const interval = setInterval(() => {
      setWordsData(prev => prev.map(word => {
        let newX = word.x + word.velocityX;
        let newY = word.y + word.velocityY;
        let newVelocityX = word.velocityX;
        let newVelocityY = word.velocityY;

        if (newX < 0 || newX > 100) {
          newVelocityX = -newVelocityX;
          newX = newX < 0 ? 0 : 100;
        }

        if (newY < 0 || newY > 100) {
          newVelocityY = -newVelocityY;
          newY = newY < 0 ? 0 : 100;
        }

        let newOpacity = Math.max(0.05, Math.min(0.5, word.opacity + (Math.random() * 0.02 - 0.01)));

        return {
          ...word,
          x: newX,
          y: newY,
          opacity: newOpacity,
          velocityX: newVelocityX,
          velocityY: newVelocityY,
        };
      }));
    }, 50);

    return () => {
      clearInterval(interval);
      window.removeEventListener('resize', initializeWords);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={`bg-gradient-to-r from-secondary-400 via-primary-400 to-primary-500 min-h-screen p-4 relative overflow-hidden ${showAudioControls ? 'pb-32' : ''
        }`}
    >
      <div className="absolute inset-0 overflow-hidden" aria-hidden="true">
        {wordsData.map((wordData, index) => (
          <div
            key={allWords[index]}
            className="absolute text-white pointer-events-none select-none"
            style={{
              left: `${wordData.x}%`,
              top: `${wordData.y}%`,
              fontSize: '4rem',
              opacity: wordData.opacity,
              transition: 'all 0.5s linear',
              zIndex: 1,
            }}
          >
            {allWords[index]}
          </div>
        ))}
      </div>
      <div className="relative z-10">
        {children}
      </div>
    </div>
  );
};

export default Background;