import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from './supabaseClient';

const SuccessPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) throw new Error('Kein Benutzer gefunden');

        const { data, error } = await supabase
          .from('user_subscriptions')
          .select('tier')
          .eq('user_id', user.id)
          .single();

        if (error) throw error;

        if (!data) {
          setError('Abonnement nicht gefunden. Bitte kontaktieren Sie den Support.');
        }
      } catch (error) {
        console.error('Fehler beim Überprüfen des Abonnementstatus:', error);
        setError('Fehler beim Überprüfen des Abonnementstatus');
      } finally {
        setLoading(false);
      }
    };

    checkSubscriptionStatus();
  }, []);

  if (loading) {
    return <div>Laden...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Vielen Dank für Ihr Abonnement!</h1>
      <p className="mb-4">Ihr Abonnement wurde erfolgreich aktiviert.</p>
      <Link to="/talebear" className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-200">
        Zurück zu talebear.com
      </Link>
    </div>
  );
};

export default SuccessPage;
