import React, { useState, useEffect, useRef, useCallback } from 'react';
import { BookOpen, Award, Send, Loader, Volume2, Play, Trash2, Settings, LogOutIcon, CreditCard, Menu, Coins, MessageSquare, ArrowUpZA, ArrowDownAZ, CalendarArrowDown, CalendarArrowUp, X } from 'lucide-react';
import { supabase, Tale, saveTale, getTales, updateTale, deleteTale, getAudioUrl, getBadWords, getUserSubscription, getUserCredits, UserSubscription, shouldRefillCredits, refillCredits, getCreditsPerRequest, getStoriesLimit, getStoryLengthOptions } from './supabaseClient';
import ProfileModal from './ProfileModal';
import AlertModal from './components/AlertModal';
import { filterContent, updateBadWords } from './utils/contentFilter';
import { updateFilteredTaleContent } from './supabaseClient';
import Background from './components/Background';
import PromptBuilder from './components/PromptBuilder';
import AudioPlayer from './components/AudioPlayer';
import SubscriptionModal from './components/SubscriptionModal';
import Footer from './components/Footer';
import FeedbackModal from './components/FeedbackModal';

interface TaleBearProps {
  onLogout: () => void;
}

// Am Anfang der Datei, fügen Sie diese Hilfsfunktion hinzu:
const logError = (message: string, error: any) => {
  console.error(message, error);
  if (error instanceof Error) {
    console.error('Error name:', error.name);
    console.error('Error message:', error.message);
    console.error('Error stack:', error.stack);
  } else {
    console.error('Unknown error type:', typeof error);
  }
};

// Fügen Sie diese neue Schnittstelle hinzu
interface CreditsPerRequest {
  generate_story: number;
  generate_audio: number;
}

// Fügen Sie diese neue Schnittstelle hinzu
interface StoryLength {
  type: string;
  label: string;
  credits: number;
}

// Fügen Sie diese neue Schnittstelle hinzu
interface CreativityLevel {
  type: string;
  label: string;
  temperature: number;
}

// Fügen Sie diese neue Interface hinzu
interface LLMModel {
  id: string;
  name: string;
  description: string;
  maxTokens: number;
}

// Fügen Sie diese neue Schnittstelle hinzu
interface SortConfig {
  key: 'title' | 'created_at';
  direction: 'asc' | 'desc';
}

// Fügen Sie diese neue Komponente am Anfang der Datei hinzu
const StoryLengthSwitch: React.FC<{
  options: StoryLength[];
  value: string;
  onChange: (value: string) => void;
}> = ({ options, value, onChange }) => {
  return (
    <div className="flex items-center space-x-1 bg-gray-100 p-1 rounded-lg w-full">
      <div className="grid grid-cols-3 gap-1 w-full">
        {options.map((option) => (
          <button
            key={option.type}
            onClick={() => onChange(option.type)}
            className={`
              px-3 py-1.5 text-xs font-medium rounded-md transition-all duration-200
              flex items-center justify-center whitespace-nowrap w-full
              ${value === option.type
                ? 'bg-white text-emerald-600 shadow-sm'
                : 'text-content-light hover:bg-gray-200'
              }
            `}
          >
            {option.label}
            <span className="ml-1 text-xs opacity-75 flex items-center">
              <Coins size={12} className="mr-0.5" />
              {option.credits}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

// Fügen Sie diese neue Komponente hinzu
const CreativitySwitch: React.FC<{
  options: CreativityLevel[];
  value: string;
  onChange: (value: string) => void;
}> = ({ options, value, onChange }) => {
  return (
    <div className="flex items-center bg-gray-100 p-1 rounded-lg w-full">
      <div className="grid grid-cols-3 gap-1 w-full">
        {options.map((option) => (
          <button
            key={option.type}
            onClick={() => onChange(option.type)}
            className={`
              px-3 py-1.5 text-xs font-medium rounded-md transition-all duration-200
              flex items-center justify-center whitespace-nowrap w-full
              ${value === option.type
                ? 'bg-white text-emerald-600 shadow-sm'
                : 'text-content-light hover:bg-gray-200'
              }
            `}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};

// Fügen Sie diese neue Komponente hinzu
const ModelSwitch: React.FC<{
  models: LLMModel[];
  value: string;
  onChange: (value: string) => void;
}> = ({ models, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const selectedModel = models.find(model => model.id === value);

  return (
    <div ref={dropdownRef} className="relative w-full">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full bg-gray-100 p-1.5 rounded-lg flex items-center justify-between text-xs font-medium hover:bg-gray-200 transition-colors duration-200"
        style={{ height: '34px' }}
      >
        <span className="flex items-center truncate">
          <Settings size={16} className="mr-2 flex-shrink-0" />
          <span className="truncate">{selectedModel?.name || 'Modell'}</span>
        </span>
        <svg
          className={`w-4 h-4 transition-transform duration-200 flex-shrink-0 ml-1 ${isOpen ? 'transform rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white rounded-lg shadow-lg border border-gray-200">
          {models.map((model) => (
            <button
              key={model.id}
              onClick={() => {
                onChange(model.id);
                setIsOpen(false);
              }}
              className={`
                w-full px-3 py-2 text-left text-xs hover:bg-gray-50 
                ${model.id === value ? 'text-emerald-600 bg-gray-50' : 'text-content-dark'}
                first:rounded-t-lg last:rounded-b-lg
              `}
              title={model.description}
            >
              <div className="font-medium truncate">{model.name}</div>
              <div className="text-xs text-content-light truncate">{model.description}</div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

const TaleBear: React.FC<TaleBearProps> = ({ onLogout }) => {
  const [tales, setTales] = useState<Tale[]>([]);
  const [currentTale, setCurrentTale] = useState<Tale | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [prompt, setPrompt] = useState('');
  const promptRef = useRef<HTMLTextAreaElement>(null);
  const [error, setError] = useState<string | null>(null);
  const [isStoryGenerated, setIsStoryGenerated] = useState(false);
  const [isReading, setIsReading] = useState(false);
  const [showAudioControls, setShowAudioControls] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const talesPerPage = 5;
  const [targetAge, setTargetAge] = useState<string>('3-5');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [isPromptBuilderOpen, setIsPromptBuilderOpen] = useState(false);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [userSubscription, setUserSubscription] = useState<UserSubscription | null>(null);
  const [userCredits, setUserCredits] = useState<number | null>(null);
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuHeight, setMenuHeight] = useState(0);
  const menuRef = useRef<HTMLDivElement>(null);
  const [creditsPerRequest, setCreditsPerRequest] = useState<CreditsPerRequest>({ generate_story: 0, generate_audio: 0 });
  const [storiesLimit, setStoriesLimit] = useState<number>(0);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [storyLength, setStoryLength] = useState<string>('generate-story');
  const [storyLengthOptions, setStoryLengthOptions] = useState<StoryLength[]>([]);
  const [creativity, setCreativity] = useState<string>('balanced');
  const [creativityOptions] = useState<CreativityLevel[]>([
    { type: 'conservative', label: 'Konservativ', temperature: 0.3 },
    { type: 'balanced', label: 'Ausgewogen', temperature: 0.7 },
    { type: 'creative', label: 'Kreativ', temperature: 1.0 }
  ]);
  const [selectedModel, setSelectedModel] = useState<string>("llama-3.1-70b-versatile");
  const AVAILABLE_MODELS: LLMModel[] = [
    {
      id: "llama-3.1-70b-versatile",
      name: "Llama 3.1 70B",
      description: "Ausgewogenes Modell mit hoher Qualität",
      maxTokens: 4096
    },
    {
      id: "mixtral-8x7b-32768",
      name: "Mixtral 8x7B",
      description: "Schnelles Modell mit langer Kontextlänge",
      maxTokens: 32768
    }
  ];

  // Fügen Sie diese Hilfsfunktionen hinzu
  const hasEnoughCreditsForStory = useCallback(() => {
    const requiredCredits = storyLengthOptions.find(option => option.type === storyLength)?.credits || 0;
    return userCredits !== null && requiredCredits <= userCredits;
  }, [userCredits, storyLength, storyLengthOptions]);

  const hasEnoughCreditsForAudio = useCallback(() => {
    return userCredits !== null && creditsPerRequest.generate_audio <= userCredits;
  }, [userCredits, creditsPerRequest.generate_audio]);

  const showAlert = useCallback((message: string) => {
    setAlertMessage(message);
  }, []);

  const loadTales = useCallback(async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const loadedTales = await getTales(user.id);
        setTales(loadedTales);
      }
    } catch (error) {
      console.error('Fehler beim Laden der Geschichten:', error);
      showAlert('Fehler beim Laden der Geschichten');
    }
  }, [showAlert]);

  const updateBadWordsList = useCallback(async () => {
    try {
      const words = await getBadWords();
      updateBadWords(words);
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Content-Filters:', error);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      document.title = "talebear.com";
    }, 0);
    loadTales();
    updateBadWordsList();
    const intervalId = setInterval(() => {
      updateBadWordsList();
    }, 3600000); // Aktualisiere jede Stunde


    return () => clearInterval(intervalId);
  }, [loadTales, updateBadWordsList]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const filterAndUpdateCurrentTale = useCallback(async (tale: Tale) => {
    const filteredTitle = filterContent(tale.title);
    const filteredContent = filterContent(tale.content);

    if (filteredTitle !== tale.title || filteredContent !== tale.content) {
      try {
        const updatedTale = await updateFilteredTaleContent(tale.id, filteredTitle, filteredContent);
        setCurrentTale(updatedTale);
        setTales(prevTales => prevTales.map(t => t.id === updatedTale.id ? updatedTale : t));
      } catch (error) {
        console.error('Fehler beim Aktualisieren des gefilterten Inhalts:', error);
        showAlert('Fehler beim Aktualisieren des gefilterten Inhalts');
      }
    } else {
      setCurrentTale(tale);
    }
  }, [setTales, showAlert]);

  const setFilteredCurrentTale = useCallback((tale: Tale | null) => {
    if (tale) {
      filterAndUpdateCurrentTale(tale);
    } else {
      setCurrentTale(null);
    }
  }, [filterAndUpdateCurrentTale]);

  const createNewTale = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        if (tales.length >= storiesLimit) {
          showAlert('Sie haben Ihr Geschichten-Limit erreicht. Bitte upgraden Sie Ihr Abonnement für mehr Geschichten.');
          return;
        }

        const newTale = await saveTale({
          title: 'Neue Geschichte',
          content: '',
        });
        setTales([newTale, ...tales]);
        setFilteredCurrentTale(newTale);
        setIsStoryGenerated(false);
      }
    } catch (error) {
      console.error('Fehler beim Erstellen einer neuen Geschichte:', error);
      showAlert('Fehler beim Erstellen einer neuen Geschichte');
    }
  };

  const updateCurrentTale = useCallback(async (updater: (prevTale: Tale) => Tale) => {
    if (currentTale) {
      const updatedTale = updater(currentTale);
      setFilteredCurrentTale(updatedTale);
      setTales(prevTales => prevTales.map(tale =>
        tale.id === updatedTale.id ? updatedTale : tale
      ));
      try {
        await updateTale(updatedTale);
        // Fügen Sie hier einen console.log hinzu, um zu überprüfen, ob die Aktualisierung erfolgt
        console.log('Tale aktualisiert:', updatedTale);
      } catch (error) {
        console.error('Fehler beim Aktualisieren der Geschichte:', error);
        showAlert('Fehler beim Aktualisieren der Geschichte');
      }
    }
  }, [currentTale, setFilteredCurrentTale, showAlert]);

  const handleDeleteTale = async (taleId: string) => {
    try {
      await deleteTale(taleId);
      setTales(tales.filter(tale => tale.id !== taleId));
      if (currentTale?.id === taleId) {
        setCurrentTale(null);
      }
    } catch (error) {
      console.error('Fehler beim Löschen der Geschichte:', error);
      showAlert('Fehler beim Löschen der Geschichte');
    }
  };

  const resetPromptField = useCallback(() => {
    console.log("resetPromptField wurde aufgerufen");
    setPrompt('');
    if (promptRef.current) {
      console.log("promptRef.current wurde gefunden");
      promptRef.current.style.height = 'auto';
    }
  }, []);

  const updateUserCreditsDisplay = useCallback(async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const credits = await getUserCredits(user.id);
        setUserCredits(credits);
      }
    } catch (error) {
      console.error('Fehler beim Aktualisieren der Benutzer-Credits:', error);
    }
  }, []);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          const subscription = await getUserSubscription(user.id);
          setUserSubscription(subscription);

          let credits = await getUserCredits(user.id);

          credits = await getUserCredits(user.id);

          setUserCredits(credits);
        }
      } catch (error) {
        console.error('Fehler beim Laden der Benutzerdaten:', error);
        showAlert('Fehler beim Laden der Benutzerdaten');
      }
    };

    loadUserData();
  }, [showAlert]);

  // Fügen Sie diesen useEffect-Hook hinzu, um die Credits pro Anfrage zu laden
  useEffect(() => {
    const loadCreditsPerRequest = async () => {
      try {
        const storyCredits = await getCreditsPerRequest('generate-story');
        const audioCredits = await getCreditsPerRequest('generate-audio');
        setCreditsPerRequest({ generate_story: storyCredits, generate_audio: audioCredits });
      } catch (error) {
        console.error('Fehler beim Laden der Credits pro Anfrage:', error);
      }
    };

    loadCreditsPerRequest();
  }, []);

  useEffect(() => {
    const loadStoriesLimit = async () => {
      if (userSubscription) {
        const limit = await getStoriesLimit(userSubscription.tier);
        setStoriesLimit(limit);
      }
    };

    loadStoriesLimit();
  }, [userSubscription]);

  useEffect(() => {
    const loadStoryLengthOptions = async () => {
      try {
        const options = await getStoryLengthOptions();
        setStoryLengthOptions(options);
      } catch (error) {
        console.error('Fehler beim Laden der Geschichtenlängen-Optionen:', error);
      }
    };

    loadStoryLengthOptions();
  }, []);

  const generateStory = async () => {
    console.log("generateStory wurde aufgerufen");
    updateBadWordsList();
    setError(null);
    try {
      if (!currentTale || !prompt) {
        console.log("Kein aktuelles Tale oder Prompt", { currentTale, prompt });
        showAlert("Bitte wählen Sie eine Geschichte aus und geben Sie einen Prompt ein.");
        return;
      }

      setIsGenerating(true);

      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Benutzer nicht authentifiziert');

      console.log("User ID:", user.id); // Fügen Sie diese Zeile hinzu, um die User ID zu überprüfen

      const selectedTemperature = creativityOptions.find(
        option => option.type === creativity
      )?.temperature || 0.7;

      const { data, error } = await supabase.functions.invoke('generate-story', {
        body: JSON.stringify({
          prompt,
          targetAge,
          userId: user.id,
          storyLength,
          temperature: selectedTemperature,
          modelId: selectedModel
        }),
      });

      if (error) {
        // Prüfen, ob es sich um einen 400 Fehler handelt
        if (error.message.includes('status code 400')) {
          const errorData = JSON.parse(error.message.split('Body:')[1]);
          if (errorData.error === 'Nicht genügend Credits') {
            showAlert(errorData.message);
            return;
          }
        }
        console.error("Fehler bei der Invoke-Funktion:", error);
        throw error;
      }

      console.log("Antwort erhalten:", data);

      if (data.error) {
        console.error('Generate story error:', data.error, data.details);
        throw new Error(`Generate story error: ${data.error}. Details: ${data.details}`);
      }

      if (data.choices && data.choices.length > 0) {
        const generatedContent = data.choices[0].message.content.trim();
        console.log("Generierter Inhalt:", generatedContent);

        // Generiere einen Titel für die Geschichte mit der neuen Funktion
        const titleResponse = await supabase.functions.invoke('generate-title', {
          body: JSON.stringify({
            storyContent: generatedContent,
            targetAge
          }),
        });

        if (titleResponse.error) throw titleResponse.error;

        const generatedTitle = titleResponse.data.choices[0].message.content.trim();

        console.log("Generierter Titel:", generatedTitle);

        await updateCurrentTale(prevTale => {
          const updatedTale = {
            ...prevTale,
            title: generatedTitle,
            content: generatedContent
          };
          console.log("Aktualisiertes Tale:", updatedTale);
          return updatedTale;
        });

        // Fügen Sie hier einen weiteren console.log hinzu
        console.log("Aktuelles Tale nach Aktualisierung:", currentTale);

        setIsStoryGenerated(true);
      } else {
        console.error("Unerwartetes Antwortformat:", data);
        throw new Error("Unerwartetes Antwortformat");
      }

      // Nach erfolgreicher Generierung, aktualisieren Sie die Credits-Anzeige
      await updateUserCreditsDisplay();

    } catch (error: unknown) {
      console.error("Detaillierter Fehler beim Generieren der Geschichte:", error);
      showAlert(`Fehler beim Generieren der Geschichte: ${error instanceof Error ? error.message : 'Unbekannter Fehler'}`);
    } finally {
      console.log("Setze isGenerating auf false");
      setIsGenerating(false);
      resetPromptField();
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !isGenerating && prompt) {
      generateStory();
    }
  };

  // Ändern Sie die handleGenerateNewAudio Funktion wie folgt:
  const handleGenerateNewAudio = async () => {
    if (!currentTale) {
      showAlert('Keine Geschichte zum Vorlesen ausgewählt');
      return;
    }

    if (!hasEnoughCreditsForAudio()) {
      handleInsufficientCredits();
      return;
    }

    setIsReading(true);

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        throw new Error('Benutzer nicht authentifiziert');
      }

      console.log('User authenticated:', user.id);

      const disclaimer = "Diese Stimme wurde von einer künstlichen Intelligenz generiert.  ";
      const filteredTitle = filterContent(currentTale.title);
      const filteredContent = filterContent(currentTale.content);
      currentTale.content = filteredContent;
      currentTale.title = filteredTitle;
      const fullText = disclaimer + filteredTitle + "\n\n" + filteredContent;

      console.log('Prepared text for audio generation:', fullText.substring(0, 100) + '...');

      try {
        console.log('Invoking generate-audio function...');
        const { data, error } = await supabase.functions.invoke('generate-audio', {
          body: JSON.stringify({ prompt: fullText, userId: user.id }),
        });

        console.log('Response from generate-audio:', { data, error });

        if (error) {
          console.error('Full error object:', error);
          if (typeof error.message === 'string' && error.message.includes('status code 400')) {
            console.log('Detected 400 status code, attempting to parse error message');
            try {
              const errorBody = error.message.split('Body:')[1];
              console.log('Extracted error body:', errorBody);
              const errorData = JSON.parse(errorBody);
              console.log('Parsed error data:', errorData);
              if (errorData.error === 'Nicht genügend Credits') {
                showAlert(errorData.message);
                return;
              }
            } catch (parseError) {
              console.error('Error parsing error message:', parseError);
            }
          }
          logError('Supabase-Funktionsfehler:', error);
          throw new Error(`Supabase-Funktionsfehler: ${error.message}`);
        }

        if (!Array.isArray(data.audioData)) {
          logError('Keine gültigen Audiodaten von der Funktion erhalten', data);
          throw new Error('Keine gültigen Audiodaten von der Funktion erhalten');
        }

        console.log('Antwort erhalten:', data);
        let audioData = new Uint8Array(data.audioData);
        console.log('Audio-Daten Länge:', audioData.length);

        console.log('Starte Speicherung der Audio-Daten...');
        const updatedTale = await saveAudioData(audioData, currentTale.id, user.id);
        if (updatedTale) {
          console.log('Tale erfolgreich aktualisiert:', updatedTale);
          setCurrentTale(updatedTale);
          setTales(prevTales => prevTales.map(t => t.id === updatedTale.id ? updatedTale : t));
        }

        console.log('Erstelle Blob und URL...');
        const audioBlob = new Blob([audioData], { type: 'audio/mpeg' });
        const audioUrl = URL.createObjectURL(audioBlob);

        console.log('Starte Audio-Wiedergabe...');
        setAudioUrl(audioUrl);
        setShowAudioControls(true);

        // Nach erfolgreicher Generierung, aktualisieren Sie die Credits-Anzeige
        await updateUserCreditsDisplay();
      } catch (error) {
        console.error('Caught error in try block:', error);
        throw error;
      }
    } catch (error) {
      console.error('Caught error in outer try block:', error);
      logError('Detaillierter Fehler beim Generieren und Abspielen der Audiodatei:', error);
      showAlert(`Fehler beim Generieren und Abspielen der Audiodatei: ${error instanceof Error ? error.message : 'Unbekannter Fehler'}`);
    } finally {
      setIsReading(false);
    }
  };

  const handlePlayExistingAudio = async () => {
    if (!currentTale) {
      showAlert('Keine Geschichte zum Vorlesen ausgewählt');
      return;
    }

    setIsReading(true);

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        throw new Error('Benutzer nicht authentifiziert');
      }

      const audioUrl = await getAudioUrl(user.id, currentTale.id);
      if (!audioUrl) {
        throw new Error('Existierende Audio-URL konnte nicht abgerufen werden');
      }

      console.log('Wiedergabe vorhandenes Audio');
      setAudioUrl(audioUrl);
      setShowAudioControls(true);
    } catch (error) {
      console.error('Fehler beim Abspielen der existierenden Audiodatei:', error);
      showAlert('Es gab einen Fehler beim Vorlesen der Geschichte. Bitte versuchen Sie es später erneut.');
    } finally {
      setIsReading(false);
    }
  };

  const handleCloseAudioControls = () => {
    setShowAudioControls(false);
    setAudioUrl(null);
  };

  const handleDownload = async () => {
    if (currentTale) {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          const audioUrl = await getAudioUrl(user.id, currentTale.id);
          if (audioUrl) {
            // Fetch der Audiodatei als Blob
            const response = await fetch(audioUrl);
            const blob = await response.blob();

            // Erstellen einer lokalen URL für den Blob
            const blobUrl = URL.createObjectURL(blob);

            // Erstellen und Klicken des unsichtbaren Download-Links
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = `${currentTale.title || 'audio'}.mp3`;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();

            // Aufräumen
            document.body.removeChild(link);
            URL.revokeObjectURL(blobUrl);
          } else {
            showAlert('Audio-URL konnte nicht abgerufen werden.');
          }
        } else {
          showAlert('Benutzer nicht authentifiziert.');
        }
      } catch (error) {
        console.error('Fehler beim Herunterladen der Audiodatei:', error);
        showAlert('Es gab einen Fehler beim Herunterladen der Audiodatei.');
      }
    } else {
      showAlert('Keine aktuelle Geschichte ausgewählt.');
    }
  };

  // Funktion zum Speichern der Audio-Daten
  const saveAudioData = async (audioData: Uint8Array, taleId: string, userId: string) => {
    try {
      console.log('Starte Speicherung der Audio-Daten...');
      const audioBlob = new Blob([audioData], { type: 'audio/mpeg' });

      const fileName = `${taleId}.mp3`;

      console.log('Lade Audio-Datei hoch...');
      const { data, error } = await supabase.storage
        .from('tale_audio')
        .upload(`${userId}/${fileName}`, audioBlob, {
          cacheControl: '3600',
          upsert: true
        });

      if (error) {
        logError('Fehler beim Hochladen der Audio-Datei:', error);
        throw error;
      }

      if (data) {
        console.log('Audio-Datei erfolgreich hochgeladen');
        const { data: { publicUrl } } = supabase.storage
          .from('tale_audio')
          .getPublicUrl(`${userId}/${fileName}`, {
            download: true,
          });

        if (publicUrl) {
          console.log('Öffentliche URL generiert:', publicUrl);
          const updatedTale = await updateTale({ id: taleId, audio_url: publicUrl });
          console.log('Tale mit neuer Audio-URL aktualisiert:', updatedTale);
          return updatedTale;
        } else {
          throw new Error('Öffentliche URL konnte nicht generiert werden');
        }
      }
    } catch (uploadError) {
      logError('Fehler beim Speichern der Audiodatei:', uploadError);
      throw uploadError;
    }
  };

  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: 'created_at',
    direction: 'desc'
  });

  const sortTales = (talesArray: Tale[]) => {
    const sortedTales = [...talesArray].sort((a, b) => {
      if (sortConfig.key === 'title') {
        const comparison = a.title.localeCompare(b.title);
        return sortConfig.direction === 'asc' ? comparison : -comparison;
      } else {
        const dateA = new Date(a.created_at).getTime();
        const dateB = new Date(b.created_at).getTime();
        return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
      }
    });
    return sortedTales;
  };

  const [searchTerm, setSearchTerm] = useState('');

  const filterTales = (talesArray: Tale[]) => {
    if (!searchTerm) return talesArray;

    return talesArray.filter(tale =>
      tale.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  // Fügen Sie diese useEffect hinzu, um die currentPage zurückzusetzen, wenn sich searchTerm oder sortConfig ändert
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, sortConfig]);

  // Aktualisieren Sie die Berechnung der gefilterten und sortierten Tales
  const getFilteredAndSortedTales = useCallback(() => {
    // Erst filtern
    const filteredTales = filterTales(tales);
    // Dann sortieren
    return sortTales(filteredTales);
  }, [tales, searchTerm, sortConfig]); // Abhängigkeiten hinzufügen

  // Ersetzen Sie die bestehende Berechnung durch:
  const sortedAndFilteredTales = getFilteredAndSortedTales();
  const totalPages = Math.ceil(sortedAndFilteredTales.length / talesPerPage);
  const indexOfLastTale = currentPage * talesPerPage;
  const indexOfFirstTale = indexOfLastTale - talesPerPage;
  const currentTales = sortedAndFilteredTales.slice(indexOfFirstTale, indexOfLastTale);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  // Fügen Sie diese neue Funktion hinzu:
  const adjustInputHeight = useCallback(() => {
    if (promptRef.current) {
      const textarea = promptRef.current;
      const select = document.querySelector('select') as HTMLSelectElement;
      const button = document.querySelector('button[onClick="generateStory"]') as HTMLButtonElement;

      textarea.style.height = 'auto';
      const newHeight = Math.max(textarea.scrollHeight, isMobile ? 64 : 38);
      textarea.style.height = `${newHeight}px`;

      if (select) select.style.height = `${newHeight}px`;
      if (button) button.style.height = `${newHeight}px`;
    }
  }, [isMobile]);



  // Fügen Sie diesen useEffect-Hook hinzu:
  useEffect(() => {
    window.addEventListener('resize', adjustInputHeight);
    return () => {
      window.removeEventListener('resize', adjustInputHeight);
    };
  }, [adjustInputHeight]);

  // Ändern Sie den useEffect-Hook, der auf Änderungen des Prompts reagiert:
  useEffect(() => {
    adjustInputHeight();
  }, [prompt, adjustInputHeight]);

  const handlePromptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPrompt(e.target.value);
  };

  const handlePromptGenerated = (generatedPrompt: string) => {
    setPrompt(generatedPrompt);
    setIsPromptBuilderOpen(false);
  };

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          const subscription = await getUserSubscription(user.id);
          setUserSubscription(subscription);

          let credits = await getUserCredits(user.id);

          // Wenn keine Credits vorhanden sind oder es Zeit für eine Auffüllung ist, füllen wir sie auf
          if (credits === 0 || await shouldRefillCredits(user.id)) {
            await refillCredits(user.id);
            credits = await getUserCredits(user.id);
          }

          setUserCredits(credits);
        }
      } catch (error) {
        console.error('Fehler beim Laden der Benutzerdaten:', error);
        showAlert('Fehler beim Laden der Benutzerdaten');
      }
    };

    loadUserData();
  }, [showAlert]);

  useEffect(() => {
    if (menuRef.current) {
      setMenuHeight(isMenuOpen ? menuRef.current.scrollHeight : 0);
    }
  }, [isMenuOpen]);

  // Fügen Sie diese Funktion am Anfang der Komponente hinzu:
  const getPageRange = useCallback((currentPage: number, totalPages: number) => {
    if (totalPages <= 5) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    if (currentPage <= 3) {
      return [1, 2, 3, 4, '...', totalPages];
    }

    if (currentPage >= totalPages - 2) {
      return [1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
    }

    return [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
  }, []);

  const handleInsufficientCredits = () => {
    setIsSubscriptionModalOpen(true);
  };

  // Fügen Sie diese neue Funktion hinzu
  const handleCreateNewTale = () => {
    if (tales.length >= storiesLimit) {
      setIsSubscriptionModalOpen(true);
    } else {
      createNewTale();
    }
  };

  // Fügen Sie am Anfang der Komponente hinzu:

  useEffect(() => {
    // Prüfe URL-Parameter beim Laden der Seite
    const urlParams = new URLSearchParams(window.location.search);
    const showSubscriptionModal = urlParams.get('showSubscriptionModal');
    const success = urlParams.get('success');
    const canceled = urlParams.get('canceled');

    // Entferne die Parameter aus der URL ohne Neuladen der Seite
    const newUrl = window.location.pathname;
    window.history.replaceState({}, '', newUrl);

    // Zeige das SubscriptionModal bei success oder showSubscriptionModal
    if (success === 'true') {
      setIsSubscriptionModalOpen(true);
      showAlert('Ihr Abonnement wurde erfolgreich aktiviert! Die Änderungen werden in wenigen Minuten wirksam.');
    } else if (canceled === 'true') {
      setIsSubscriptionModalOpen(true);
      showAlert('Der Zahlungsvorgang wurde abgebrochen.');
    } else if (showSubscriptionModal === 'true') {
      setIsSubscriptionModalOpen(true);
    }
  }, [showAlert]);

  // Fügen Sie diese Hilfsfunktion hinzu
  const getRequiredCredits = useCallback(() => {
    return storyLengthOptions.find(option => option.type === storyLength)?.credits || 0;
  }, [storyLength, storyLengthOptions]);

  // Im TaleBear-Komponenten, fügen Sie diese Funktion hinzu (vor dem return statement):
  const handleSort = (key: SortConfig['key']) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  return (
    <Background showAudioControls={false}>
      <a
        href="#main-content"
        className="sr-only focus:not-sr-only focus:absolute focus:z-50 focus:p-4 focus:bg-white focus:text-primary-600"
      >
        Zum Hauptinhalt springen
      </a>
      <div className="flex flex-col min-h-screen">
        <div className="flex-grow">
          {/* Bestehender Content */}
          <div className="max-w-sm md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto">
            <nav className="bg-white rounded-lg shadow-lg p-2 mb-4">
              <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold text-primary-600 font-heading">talebear.com</h1>
                <div className="md:hidden">
                  <button
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                    className="text-content hover:text-content-dark"
                  >
                    <Menu size={24} />
                  </button>
                </div>
                <div className="hidden md:flex items-center space-x-2">
                  {userSubscription ? (
                    <div className="text-sm text-content mr-4">
                      <span className="font-semibold">{userSubscription.tier.charAt(0).toUpperCase() + userSubscription.tier.slice(1)}</span>
                    </div>
                  ) : (
                    <div className="text-sm text-content mr-4">
                      <span className="font-semibold">Kein Abo</span>
                    </div>
                  )}
                  {userCredits !== null && (
                    <div className="flex items-center text-sm text-content mr-4">
                      <Coins size={16} className="mr-1" />
                      <span>{userCredits}</span>
                    </div>
                  )}
                  {/* Hier fügen wir den Feedback-Button ein */}
                  <button
                    onClick={() => setIsFeedbackModalOpen(true)}
                    className="bg-secondary-500 text-white p-2 rounded-lg hover:bg-secondary-600 transition-colors duration-200 flex items-center mr-2"
                    title="Feedback geben"
                  >
                    <MessageSquare size={20} />
                  </button>
                  <button
                    onClick={() => setIsSubscriptionModalOpen(true)}
                    className="bg-primary-500 text-white p-2 rounded-lg hover:bg-primary-600 transition-colors duration-200 flex items-center mr-2"
                    title="Abonnement verwalten"
                  >
                    <CreditCard size={20} />
                  </button>
                  <button
                    onClick={() => setIsProfileModalOpen(true)}
                    className="bg-primary-500 text-white p-2 rounded-lg hover:bg-primary-600 transition-colors duration-200 flex items-center"
                    title="Einstellungen"
                  >
                    <Settings size={20} />
                  </button>
                  <button
                    onClick={onLogout}
                    className="bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 transition-colors duration-200"
                    title="Abmelden"
                  >
                    <LogOutIcon size={20} />
                  </button>
                </div>
              </div>
              <div
                ref={menuRef}
                className="overflow-hidden transition-[max-height] duration-300 ease-in-out"
                style={{ maxHeight: `${menuHeight}px` }}
              >
                <div className="mt-4 md:hidden">
                  <div className="flex flex-col space-y-2">
                    {userSubscription ? (
                      <div className="text-sm text-content">
                        <span className="font-semibold">{userSubscription.tier.charAt(0).toUpperCase() + userSubscription.tier.slice(1)}</span>
                      </div>
                    ) : (
                      <div className="text-sm text-content">
                        <span className="font-semibold">Kein Abo</span>
                      </div>
                    )}
                    {userCredits !== null && (
                      <div className="flex items-center text-sm text-content">
                        <Coins size={16} className="mr-1" />
                        <span>{userCredits}</span>
                      </div>
                    )}
                    {/* Hier fügen wir den Feedback-Button für Mobile hinzu */}
                    <button
                      onClick={() => setIsFeedbackModalOpen(true)}
                      className="bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-purple-600 transition-colors duration-200 flex items-center justify-center"
                    >
                      <MessageSquare size={20} className="mr-2" />
                      Feedback geben
                    </button>
                    <button
                      onClick={() => setIsSubscriptionModalOpen(true)}
                      className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center"
                    >
                      <CreditCard size={20} className="mr-2" />
                      Abonnement verwalten
                    </button>
                    <button
                      onClick={() => setIsProfileModalOpen(true)}
                      className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center"
                    >
                      <Settings size={20} className="mr-2" />
                      Einstellungen
                    </button>
                    <button
                      onClick={onLogout}
                      className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors duration-200 flex items-center justify-center"
                    >
                      <LogOutIcon size={20} className="mr-2" />
                      Abmelden
                    </button>
                  </div>
                </div>
              </div>
            </nav>
          </div>

          {/* Ladeindikator */}
          {isGenerating && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-4 rounded-lg shadow-lg flex items-center">
                <Loader className="animate-spin text-emerald-500 mr-2" size={24} />
                <span className="text-content-dark font-semibold">Geschichte wird gewoben...</span>
              </div>
            </div>
          )}

          <div className="bg-white rounded-lg shadow-lg p-4 max-w-sm md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto">

            {/* Hauptinhalt der App */}
            <div className="flex flex-col items-center">
              <div className="w-full max-w-md flex flex-col items-center mb-4">
                <button
                  className={`bg-primary-500 text-white p-2 rounded-lg flex flex-col items-center justify-center text-sm font-semibold hover:bg-primary-600 transition-colors duration-200 w-full mb-4 font-heading relative ${tales.length >= storiesLimit ? 'bg-yellow-500 hover:bg-yellow-600' : ''
                    }`}
                  onClick={handleCreateNewTale}
                >
                  <BookOpen className="mb-1" size={20} />
                  {tales.length >= storiesLimit ? 'Upgrade für mehr Geschichten' : 'Neue Geschichte erfinden'}
                  <span className={`absolute -top-2 -right-2 ${tales.length >= storiesLimit ? 'bg-red-500' : 'bg-blue-500'
                    } text-white text-xs rounded-full px-2 py-1`}>
                    {tales.length}/{storiesLimit}
                  </span>
                </button>

                {tales.length > 0 && (
                  <div className="bg-gray-100 p-3 rounded-lg w-full">
                    <h2 className="text-lg font-bold mb-2 text-content-dark font-heading">Deine Geschichten</h2>

                    {/* Suchfeld und Sortierungsbuttons */}
                    <div className="flex justify-between items-center mb-2 gap-2">
                      {/* Suchfeld mit Reset-Button */}
                      <div className="relative flex-grow max-w-md">
                        <input
                          type="text"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          placeholder="Geschichten durchsuchen..."
                          className="w-full px-3 py-1.5 pr-8 text-sm rounded-lg border border-gray-200 focus:border-primary-500 focus:ring-2 focus:ring-primary-100 transition-colors duration-200"
                          style={{ height: '34px' }}
                        />
                        {searchTerm && (
                          <button
                            onClick={() => setSearchTerm('')}
                            className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 transition-colors"
                            title="Suche zurücksetzen"
                          >
                            <X size={16} />
                          </button>
                        )}
                      </div>

                      {/* Sortierungsbuttons */}
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleSort('title')}
                          className={`flex items-center px-3 rounded text-xs ${sortConfig.key === 'title'
                              ? 'bg-primary-500 text-white'
                              : 'bg-gray-200 text-content hover:bg-gray-300'
                            }`}
                          style={{ height: '34px' }}
                          title="Nach Titel sortieren"
                        >
                          {sortConfig.key === 'title' && sortConfig.direction === 'desc' ? (
                            <ArrowUpZA size={14} className="mr-1" />
                          ) : (
                            <ArrowDownAZ size={14} className="mr-1" />
                          )}
                        </button>
                        <button
                          onClick={() => handleSort('created_at')}
                          className={`flex items-center px-3 rounded text-xs ${sortConfig.key === 'created_at'
                              ? 'bg-primary-500 text-white'
                              : 'bg-gray-200 text-content hover:bg-gray-300'
                            }`}
                          style={{ height: '34px' }}
                          title="Nach Datum sortieren"
                        >
                          {sortConfig.key === 'created_at' && sortConfig.direction === 'desc' ? (
                            <CalendarArrowDown size={14} className="mr-1" />
                          ) : (
                            <CalendarArrowUp size={14} className="mr-1" />
                          )}
                        </button>
                      </div>
                    </div>

                    <ul className="space-y-2 mb-4">
                      {currentTales.map((tale) => (
                        <li
                          key={tale.id}
                          className={`flex items-center justify-between bg-white p-2 rounded-md shadow text-sm cursor-pointer hover:bg-gray-50 transition-colors ${tale.id === currentTale?.id ? 'border-2 border-primary-500' : ''
                            }`}
                          onClick={() => setFilteredCurrentTale(tale)}
                        >
                          <span className="font-medium text-content">{tale.title}</span>
                          <div className="flex items-center">
                            {tale.id === currentTale?.id && tale.content === '' && <Award className="text-yellow-500 mr-2" size={16} />}
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteTale(tale.id);
                              }}
                              className="text-red-500 hover:text-red-700"
                              aria-label={`Geschichte "${tale.title}" löschen`}
                              title={`Geschichte "${tale.title}" löschen`}
                            >
                              <Trash2 size={16} />
                              <span className="sr-only">Geschichte "{tale.title}" löschen</span>
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                    {totalPages > 1 && (
                      <div className="flex justify-center items-center space-x-1 mt-4 text-sm">
                        <button
                          onClick={() => paginate(Math.max(1, currentPage - 1))}
                          disabled={currentPage === 1}
                          className="px-1.5 py-0.5 rounded bg-gray-200 text-content-dark hover:bg-gray-300 disabled:opacity-50"
                        >
                          &lt;
                        </button>
                        {getPageRange(currentPage, totalPages).map((page, index) => (
                          <React.Fragment key={index}>
                            {typeof page === 'number' ? (
                              <button
                                onClick={() => paginate(page)}
                                className={`px-2 py-0.5 rounded ${currentPage === page
                                    ? 'bg-primary-500 text-white'
                                    : 'bg-gray-200 text-content hover:bg-gray-300'
                                  }`}
                              >
                                {page}
                              </button>
                            ) : (
                              <span className="px-1 py-0.5">...</span>
                            )}
                          </React.Fragment>
                        ))}
                        <button
                          onClick={() => paginate(Math.min(totalPages, currentPage + 1))}
                          disabled={currentPage === totalPages}
                          className="px-1.5 py-0.5 rounded bg-gray-200 text-content-dark hover:bg-gray-300 disabled:opacity-50"
                        >
                          &gt;
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="w-full md:w-3/4">
                {currentTale && (
                  <div>
                    <input
                      type="text"
                      id="story-title"
                      className="w-full p-2 border rounded mb-2 font-bold text-content"
                      value={currentTale.title}
                      onChange={(e) => updateCurrentTale(prevTale => ({ ...prevTale, title: e.target.value }))}
                      placeholder="Titel der Geschichte"
                    />
                    <textarea
                      id="story-content"
                      className={`w-full h-64 md:h-[calc(100vh-300px)] p-2 border rounded mb-2 text-content ${!currentTale.content && 'bg-gray-100'}`}
                      value={currentTale.content}
                      onChange={(e) => updateCurrentTale(prevTale => ({ ...prevTale, content: e.target.value }))}
                      placeholder="Hier erscheint bald die Geschichte..."
                      disabled={isGenerating || (!currentTale.content && !isStoryGenerated)}
                    />

                    {/* Fügen Sie hier eine neue div mit zusätzlichem Abstand hinzu */}
                    <div className="mb-6">
                      {/* Story Settings über dem Promptfeld */}
                      <div className="flex flex-col lg:flex-row justify-center gap-4 mb-4 w-full">
                        <div className="flex flex-col md:flex-row lg:w-2/3 gap-4">
                          <div className="w-full md:w-1/2">
                            <StoryLengthSwitch
                              options={storyLengthOptions}
                              value={storyLength}
                              onChange={setStoryLength}
                            />
                          </div>
                          <div className="w-full md:w-1/2">
                            <CreativitySwitch
                              options={creativityOptions}
                              value={creativity}
                              onChange={setCreativity}
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-1/3">
                          <ModelSwitch
                            models={AVAILABLE_MODELS}
                            value={selectedModel}
                            onChange={setSelectedModel}
                          />
                        </div>
                      </div>

                      {/* Prompt-Bereich */}
                      <div className="flex mb-2">
                        <label htmlFor="target-age" className="sr-only">
                          Zielgruppe: Alter des Kindes
                        </label>
                        <select
                          id="target-age"
                          name="target-age"
                          aria-label="Wähle das Alter des Kindes"
                          className="p-2 border rounded-l text-sm flex-shrink-0"
                          value={targetAge}
                          onChange={(e) => setTargetAge(e.target.value)}
                        >
                          <option value="" disabled>Alter</option>
                          <option value="6-12 Monaten">6-12 Mon.</option>
                          <option value="1-2 Jahren">1-2 J.</option>
                          <option value="3-5 Jahren">3-5 J.</option>
                          <option value="6-8 Jahren">6-8 J.</option>
                          <option value="9-12 Jahren">9-12 J.</option>
                        </select>

                        <div className="
                          relative flex-grow
                          grid
                          text-sm
                          after:px-3.5
                          after:py-2.5
                          [&>textarea]:text-inherit
                          after:text-inherit
                          [&>textarea]:resize-none
                          [&>textarea]:overflow-hidden
                          [&>textarea]:[grid-area:1/1/2/2]
                          after:[grid-area:1/1/2/2]
                          after:whitespace-pre-wrap
                          after:invisible
                          after:content-[attr(data-cloned-val)_'_']
                          after:border
                        ">
                          <textarea
                            ref={promptRef}
                            className="w-full text-slate-600 bg-slate-100 border border-transparent hover:border-secondary-400 appearance-none px-3.5 py-2.5 outline-none focus:bg-white focus:border-secondary-400 focus:ring-2 focus:ring-secondary-100 text-sm"
                            value={prompt}
                            onChange={handlePromptChange}
                            onKeyUp={(e) => {
                              if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                handleKeyPress(e as any);
                              }
                            }}
                            placeholder={isMobile ? "Geschichte beschreiben..." : "Gib eine Beschreibung deiner Geschichte ein..."}
                            disabled={isGenerating}
                            rows={isMobile ? 3 : 2}
                          />
                        </div>
                        <button
                          className="bg-primary-500 text-white p-2 rounded-r flex items-center justify-center hover:bg-primary-600 transition-colors duration-200 relative flex-shrink-0"
                          onClick={hasEnoughCreditsForStory() ? generateStory : handleInsufficientCredits}
                        >
                          <Send size={24} />
                          {userCredits !== null && (
                            <span className={`absolute -top-3 -right-3 ${hasEnoughCreditsForStory() ? 'bg-primary-500' : 'bg-red-500'} text-white text-xs rounded-full px-2 py-1 flex items-center`}>
                              {getRequiredCredits()}/{userCredits} <Coins size={12} className="ml-1" />
                            </span>
                          )}
                        </button>
                      </div>
                    </div>

                    {/* Fügen Sie den PromptBuilder hier ein */}
                    <PromptBuilder
                      onPromptGenerated={handlePromptGenerated}
                      isOpen={isPromptBuilderOpen}
                      onToggle={() => setIsPromptBuilderOpen(!isPromptBuilderOpen)}
                    />

                    {currentTale.content && (
                      <div className="grid grid-cols-2 gap-2 mb-4 w-full">
                        <button
                          className={`${currentTale.audio_url ? 'col-span-1' : 'col-span-2'} bg-secondary-400 p-2 rounded-lg flex flex-col items-center justify-center text-xs font-medium hover:bg-secondary-500 transition-colors duration-200 relative`}
                          onClick={handleGenerateNewAudio}
                          disabled={!currentTale || currentTale.content.trim() === '' || isReading || audioUrl !== null}
                        >
                          {isReading ? (
                            <Loader className="animate-spin mb-1" size={20} />
                          ) : audioUrl !== null ? (
                            <Volume2 className="mb-1" size={20} />
                          ) : (
                            <Volume2 className="mb-1" size={20} />
                          )}
                          {isReading ? 'Wird vorbereitet...' : audioUrl !== null ? 'Wird vorgelesen...' : 'Neu Vorlesen'}
                          {userCredits !== null && (
                            <span className={`absolute -top-2 -right-2 ${hasEnoughCreditsForAudio() ? 'bg-primary-500' : 'bg-red-500'} text-white text-xs rounded-full px-2 py-1 flex items-center`}>
                              {creditsPerRequest.generate_audio}/{userCredits} <Coins size={12} className="ml-1" />
                            </span>
                          )}
                        </button>
                        {currentTale.audio_url && (
                          <button
                            className="col-span-1 bg-primary-400 p-2 rounded-lg flex flex-col items-center justify-center text-xs font-medium hover:bg-primary-500 transition-colors duration-200"
                            onClick={handlePlayExistingAudio}
                            disabled={isReading || audioUrl !== null}
                          >
                            <Play className="mb-1" size={20} />
                            Aus der Erinnerung
                          </button>
                        )}
                      </div>
                    )}

                    {error && (
                      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-2" role="alert">
                        <span className="block sm:inline">{error}</span>
                      </div>
                    )}

                  </div>
                )}
              </div>
            </div>

            {/* Neuer Disclaimer am Ende der Seite */}
            <div className="bg-gray-100 border-l-4 border-gray-300 text-content p-3 mt-4 text-sm" role="alert">
              <p className="font-semibold mb-1 font-heading">Hinweis:</p>
              <p>Diese App ist für Erwachsene gedacht, die Geschichten für Kinder erstellen möchten. Bitte prüfen Sie generierte Inhalte vor dem Teilen mit Kindern.</p>
            </div>
          </div>
        </div>

        {/* Footer mit bedingtem Margin */}
        <div className={showAudioControls ? 'mb-28' : ''}>
          <Footer />
        </div>

        {/* AudioPlayer */}
        {showAudioControls && currentTale && (
          <AudioPlayer
            audioUrl={audioUrl}
            title={currentTale.title}
            onClose={handleCloseAudioControls}
            onDownload={handleDownload}
          />
        )}

        {/* Modals */}
        <ProfileModal
          isOpen={isProfileModalOpen}
          onClose={() => setIsProfileModalOpen(false)}
        />

        <AlertModal
          isOpen={alertMessage !== null}
          message={alertMessage || ''}
          onClose={() => setAlertMessage(null)}
        />

        <SubscriptionModal
          isOpen={isSubscriptionModalOpen}
          onClose={() => setIsSubscriptionModalOpen(false)}
        />

        <FeedbackModal
          isOpen={isFeedbackModalOpen}
          onClose={() => setIsFeedbackModalOpen(false)}
        />
      </div>
    </Background>
  );
};

export default TaleBear;
