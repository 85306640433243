import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from './supabaseClient';
import { Loader, EyeIcon, EyeOffIcon } from 'lucide-react';
import Background from './components/Background';
import AlertModal from './components/AlertModal';
import { validatePassword, PasswordValidationResult } from './App';

const ResetPassword: React.FC = () => {
  const [newPassword, setNewPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState<PasswordValidationResult>({ isValid: false, errors: [] });
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!passwordValidation.isValid) {
      setAlertMessage('Bitte überprüfen Sie die Passwortanforderungen.');
      return;
    }
    setIsLoading(true);
    try {
      const { error } = await supabase.auth.updateUser({ password: newPassword });
      if (error) throw error;
      setAlertMessage('Passwort erfolgreich zurückgesetzt.\nSie werden gleich wieder angemeldet.');
      setTimeout(() => navigate('/'), 3000);
    } catch (error) {
      console.error('Fehler beim Zurücksetzen des Passworts:', error);
      setAlertMessage('Es gab einen Fehler beim Zurücksetzen des Passworts. Bitte versuchen Sie es später erneut.');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const password = e.target.value;
    setNewPassword(password);
    setPasswordValidation(validatePassword(password));
  };

  const getLabelStyle = (value: string) => {
    return `absolute left-2 transition-all duration-200 ${value ? '-top-2.5 text-xs bg-white px-1' : 'top-3 text-base'
      } text-content-light pointer-events-none`;
  };

  return (
    <Background showAudioControls={false}>
      <div className="relative z-10 flex flex-col items-center justify-center min-h-screen p-4">
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
          <h2 className="text-2xl font-bold mb-4 text-content-dark">Neues Passwort setzen</h2>
          <form onSubmit={handleSubmit}>
            <div className="relative mb-4">
              <input
                type={showPassword ? "text" : "password"}
                value={newPassword}
                onChange={handlePasswordChange}
                className="border p-2 w-full pr-10 h-12"
                id="newPassword"
                required
              />
              <label htmlFor="newPassword" className={getLabelStyle(newPassword)}>
                Neues Passwort
              </label>
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-2 top-1/2 -translate-y-1/2"
              >
                {showPassword ? <EyeOffIcon size={20} /> : <EyeIcon size={20} />}
              </button>
            </div>
            <div className="mb-4">
              <h3 className="text-sm font-semibold mb-2">Passwortanforderungen:</h3>
              <ul className="text-xs">
                {['Mindestens 8 Zeichen', 'Mindestens ein Großbuchstabe', 'Mindestens ein Kleinbuchstabe', 'Mindestens eine Zahl', 'Mindestens ein Sonderzeichen'].map((requirement, index) => (
                  <li key={index} className={passwordValidation.errors.includes(requirement) ? 'text-red-500' : 'text-green-500'}>
                    {requirement}
                  </li>
                ))}
              </ul>
            </div>
            <button
              type="submit"
              className={`w-full p-3 rounded-lg font-semibold transition-colors ${isLoading || !passwordValidation.isValid
                  ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
                  : 'bg-blue-500 text-white hover:bg-blue-600'
                }`}
              disabled={isLoading || !passwordValidation.isValid}
            >
              {isLoading ? (
                <div className="flex items-center justify-center">
                  <Loader className="animate-spin mr-2" size={20} />
                  <span>Passwort wird zurückgesetzt...</span>
                </div>
              ) : (
                'Passwort zurücksetzen'
              )}
            </button>
          </form>
        </div>
      </div>
      <AlertModal
        isOpen={alertMessage !== null}
        message={alertMessage || ''}
        onClose={() => setAlertMessage(null)}
      />
    </Background>
  );
};

export default ResetPassword;