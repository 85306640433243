import React, { useEffect, useState, useCallback } from 'react';
import { supabase } from './supabaseClient';
import { Loader } from 'lucide-react';
import { toast } from 'react-hot-toast';

interface PriceOption {
  id: string;
  price: number;
  interval: string;
}

interface Plan {
  id: string;
  name: string;
  description: string;
  monthly: PriceOption | null;
  yearly: PriceOption | null;
}

interface UserSubscription {
  tier: string;
  end_date: string;
  stripe_subscription_id: string | null;
  monthly: boolean;
}

const SubscriptionPage: React.FC = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentSubscription, setCurrentSubscription] = useState<UserSubscription | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isYearly, setIsYearly] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      console.log('Fetching data...');
      const [plansData, subscriptionData] = await Promise.all([
        fetchPlans(),
        fetchCurrentSubscription()
      ]);
      console.log('Fetched plans:', plansData);
      console.log('Fetched subscription:', subscriptionData);
      setPlans(plansData);
      setCurrentSubscription(subscriptionData);

      // Setze isYearly basierend auf dem monthly Feld
      if (subscriptionData) {
        setIsYearly(!subscriptionData.monthly);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Fehler beim Laden der Daten');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    console.log('SubscriptionPage mounted');
    fetchData();
  }, [fetchData]);

  const fetchPlans = async (): Promise<Plan[]> => {
    console.log('Fetching plans...');
    try {
      const { data, error } = await supabase.functions.invoke('get-stripe-plans');
      if (error) {
        console.error('Error from get-stripe-plans:', error);
        throw error;
      }
      console.log('Plans fetched:', data);
      return data;
    } catch (error) {
      console.error('Error fetching plans:', error);
      throw error;
    }
  };

  const fetchCurrentSubscription = async (): Promise<UserSubscription | null> => {
    console.log('Fetching current subscription...');
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Kein Benutzer gefunden');

      const { data, error } = await supabase
        .from('user_subscriptions')
        .select('tier, end_date, stripe_subscription_id, monthly')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      console.log('Current subscription fetched:', data);
      return data;
    } catch (error) {
      console.error('Error fetching current subscription:', error);
      throw error;
    }
  };

  const handleSubscribe = async (priceId: string) => {
    try {
      setLoading(true);
      const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;

      const { data, error } = await supabase.functions.invoke('create-stripe-checkout', {
        body: JSON.stringify({ priceId }),
        headers: {
          Authorization: `Bearer ${sessionData.session?.access_token}`,
        },
      });

      if (error) throw error;
      if (data && data.url) {
        window.location.replace(data.url);
      } else {
        throw new Error('Keine gültige URL erhalten');
      }
    } catch (error: any) {
      console.error('Error creating checkout session:', error);
      toast.error(error.message || 'Fehler beim Erstellen der Checkout-Session');
      setLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      setLoading(true);
      const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;

      if (!currentSubscription?.stripe_subscription_id) {
        throw new Error('Keine gültige Abonnement-ID gefunden');
      }

      const { error } = await supabase.functions.invoke('cancel-stripe-subscription', {
        body: JSON.stringify({ subscriptionId: currentSubscription.stripe_subscription_id }),
        headers: {
          Authorization: `Bearer ${sessionData.session?.access_token}`,
        },
      });

      if (error) throw error;

      toast.success('Ihr Abonnement wurde erfolgreich gekündigt.');
      await fetchData();
    } catch (error) {
      console.error('Fehler beim Kündigen des Abonnements:', error);
      toast.error('Fehler beim Kündigen des Abonnements. Bitte versuchen Sie es später erneut.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateSubscription = async () => {
    try {
      setLoading(true);
      const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
      if (sessionError) throw sessionError;

      const { data, error } = await supabase.functions.invoke('create-stripe-portal', {
        headers: {
          Authorization: `Bearer ${sessionData.session?.access_token}`,
        },
      });

      if (error) throw error;

      if (data && data.url) {
        // Direkte Weiterleitung ohne setState
        window.location.replace(data.url); // Verwende replace statt href
        return; // Früher Return, um setState zu vermeiden
      } else {
        throw new Error('Keine gültige Portal-URL erhalten');
      }
    } catch (error: any) {
      console.error('Fehler beim Öffnen des Kundenportals:', error);
      toast.error(`Fehler beim Öffnen des Kundenportals: ${error.message || 'Unbekannter Fehler'}`);
      setLoading(false); // Nur im Fehlerfall loading zurücksetzen
    }
  };

  const getIntervalText = (interval: string) => {
    switch (interval) {
      case 'month':
        return 'Monat';
      case 'year':
        return 'Jahr';
      default:
        return interval;
    }
  };

  const getSubscriptionTypeText = (subscription: UserSubscription | null) => {
    if (!subscription || subscription.tier === 'free') {
      return 'Sie haben derzeit kein aktives Abonnement.';
    }
    const plan = plans.find(p => p.name.toLowerCase() === subscription.tier);
    if (!plan) return 'Abonnement-Details konnten nicht geladen werden.';

    // Verwende subscription.monthly direkt
    return `Sie haben derzeit ein ${subscription.monthly ? 'monatliches' : 'jährliches'} ${subscription.tier.charAt(0).toUpperCase() + subscription.tier.slice(1)}-Abonnement.`;
  };

  if (loading) {
    return <div className="flex justify-center items-center h-screen"><Loader className="animate-spin" size={48} /></div>;
  }

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  console.log('Rendering SubscriptionPage', { plans, currentSubscription, isYearly });

  const isPaidTier = currentSubscription && currentSubscription.tier !== 'free';

  // Drehe die Reihenfolge der Pläne um
  const reversedPlans = [...plans].reverse();

  return (
    <div className="px-4 py-8">
      {currentSubscription && (
        <div className="mb-8 p-4 bg-gray-200 rounded-lg">
          <h2 className="text-xl font-semibold mb-2">Aktuelles Abonnement</h2>
          <div className="bg-gray-500 rounded-lg p-2 inline-block h-fit">
            <p className="text-center text-base text-white font-bold">
              {currentSubscription.tier.charAt(0).toUpperCase() + currentSubscription.tier.slice(1)}
            </p>
          </div>
          <p className="mt-2 text-lg">{getSubscriptionTypeText(currentSubscription)}</p>
          <p className="mt-2">Gültig bis: {new Date(currentSubscription.end_date).toLocaleDateString()}</p>
          {isPaidTier && (
            <div className="mt-4 space-x-4">
              <button
                onClick={handleUpdateSubscription}
                className="text-primary-500 hover:underline inline-flex items-center space-x-2"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Loader className="animate-spin h-4 w-4" />
                    <span>Wird geladen...</span>
                  </>
                ) : (
                  <span>Zahlungsdaten verwalten</span>
                )}
              </button>
              <button
                onClick={handleCancelSubscription}
                className="text-red-500 hover:underline inline-flex items-center space-x-2"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Loader className="animate-spin h-4 w-4" />
                    <span>Wird gekündigt...</span>
                  </>
                ) : (
                  <span>Abonnement kündigen</span>
                )}
              </button>
            </div>
          )}
        </div>
      )}
      <div className="mb-6 flex justify-center items-center">
        <span className={`mr-2 ${!isYearly ? 'font-bold' : ''}`}>Monatlich</span>
        <label className="switch">
          <input
            type="checkbox"
            checked={isYearly}
            onChange={() => setIsYearly(!isYearly)}
            disabled={loading}
          />
          <span className="slider round"></span>
        </label>
        <span className={`ml-2 ${isYearly ? 'font-bold' : ''}`}>Jährlich</span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {!isPaidTier && (
          <div className="border rounded-lg p-6 flex flex-col">
            <h2 className="text-xl font-semibold mb-4">Kostenloser Tarif</h2>
            <p className="text-2xl font-bold mb-2">0€ / Monat</p>
            <p className="flex-grow">Zugang zu bereits erstellten Geschichten</p>
            <button
              className="mt-auto py-2 px-4 rounded bg-green-500 text-white"
              disabled
            >
              Aktuell ausgewählt
            </button>
          </div>
        )}
        {reversedPlans.map((plan) => {
          console.log('Rendering plan:', plan);
          const priceOption = isYearly ? plan.yearly : plan.monthly;
          if (!priceOption) {
            console.log(`No ${isYearly ? 'yearly' : 'monthly'} price option for plan:`, plan);
            return null;
          }

          const isCurrentPlan = currentSubscription?.tier === plan.name.toLowerCase();
          const isDifferentInterval = isCurrentPlan && currentSubscription?.monthly === isYearly;

          return (
            <div key={plan.id} className="border rounded-lg p-6 flex flex-col">
              <h2 className="text-xl font-semibold mb-4">{plan.name}</h2>
              <p className="text-2xl font-bold mb-2">
                {priceOption.price}€ / {getIntervalText(priceOption.interval)}
              </p>
              <p className="flex-grow mb-4">{plan.description}</p>
              <button
                onClick={() => handleSubscribe(priceOption.id)}
                className={`mt-auto py-2 px-4 rounded ${isCurrentPlan && !isDifferentInterval
                    ? 'bg-secondary-500 text-white'
                    : 'bg-primary-500 text-white hover:bg-primary-600'
                  }`}
                disabled={loading}
              >
                {isCurrentPlan
                  ? isDifferentInterval
                    ? `Zu ${isYearly ? 'jährlicher' : 'monatlicher'} Zahlung wechseln`
                    : 'Aktuelles Abonnement'
                  : currentSubscription?.tier
                    ? 'Abonnement ändern'
                    : 'Auswählen'}
              </button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SubscriptionPage;
